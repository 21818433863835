import React from 'react'
import Helmet from 'react-helmet'

import 'font-awesome/css/font-awesome.css'
import './all.sass'

import { Hero, HeroBody } from 'bloomer'

const TemplateWrapper = ({ children }) => (
  <div>
    <Helmet title="Stock linear regression" />
    <Hero isFullHeight>
      <HeroBody>{children}</HeroBody>
    </Hero>
  </div>
)

export default TemplateWrapper
