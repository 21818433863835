import React from 'react'
import {
  Container,
  Title,
  Field,
  Control,
  FieldLabel,
  FieldBody,
  Button,
  Input,
  Box,
  Columns,
  Column,
  Tag,
} from 'bloomer'
import regression from 'regression'
import Chart from 'chart.js'
import axios from 'axios'
import 'chartjs-plugin-colorschemes'
import Layout from '../components/layout'

function standardDeviation(values) {
  var avg = average(values)

  var squareDiffs = values.map(function(value) {
    var diff = value - avg
    var sqrDiff = diff * diff
    return sqrDiff
  })

  var avgSquareDiff = average(squareDiffs)

  var stdDev = Math.sqrt(avgSquareDiff)
  return stdDev
}

function average(data) {
  var sum = data.reduce(function(sum, value) {
    return sum + value
  }, 0)

  var avg = sum / data.length
  return avg
}

function diff_years(dt2, dt1) 
 {
  var diff =(dt2.getTime() - dt1.getTime()) / 1000;
   diff /= (60 * 60 * 24);
  return Math.abs((diff/365.25)); 
 }

function annualPerformance(values) {
  const keys = Object.keys(values)
  const firstValue = values[keys[0]]
  const lastValue = values[keys[keys.length-1]]

  const historicalPerformance = (lastValue.value - firstValue.value) / firstValue.value;
  const yearsCount = diff_years(new Date(lastValue.label), new Date(firstValue.label));
  const annualPerformance = historicalPerformance / yearsCount
  return annualPerformance
}

function getStockData(stockTicker) {
  return axios.post('/.netlify/functions/getStockData', {
    ticker: stockTicker,
  })
}

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      chart: null,
      stockTicker: '',
      stockData: [],
      regressionLines: [],
      refreshing: false,
      renderCanvas: false,
    }
  }

  componentDidMount() {}

  transformStockData(stockDataSeries) {
    const closeValues = []
    for (let property in stockDataSeries) {
      const value = parseFloat(stockDataSeries[property]['5. adjusted close'])
      if (value > 0) {
        closeValues.push({
          label: property,
          value: parseFloat(value),
        })
      }
    }
    return closeValues.reverse()
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.refreshGraph()
    }
  }

  createGraph() {
    this.setState({ renderCanvas: true })
    const ctx = document.getElementById('chart-canvas')
    const data = this.transformStockData(
      this.state.stockData['Weekly Adjusted Time Series']
    )
    if (this.state.chart) {
      this.state.chart.destroy()
    }

    const regressionLine = regression.linear(
      data.map((element, index) => {
        return [index, element.value]
      })
    )
    
    const values = data.map(element => element.value)
    const stdDev = standardDeviation(values)
    const performance = annualPerformance(data)
    this.setState({ standardDeviation: stdDev, annualPerformance: performance })

    const stockTicker = this.state.stockData['Meta Data']['2. Symbol']
    const chart = new Chart(ctx, {
      type: 'line',
      label: stockTicker,
      data: {
        labels: data.map(element => element.label),
        datasets: [
          {
            label: stockTicker,
            data: values,
            fill: false,
          },
          {
            label: 'regression-2',
            data: data.map(
              (element, index) => regressionLine.predict(index)[1] - stdDev * 2
            ),
            pointRadius: 0,
            fill: false,
            borderDash: [5, 20],
          },
          {
            label: 'regression-1',
            data: data.map(
              (element, index) => regressionLine.predict(index)[1] - stdDev
            ),
            pointRadius: 0,
            fill: false,
            borderDash: [10, 15],
          },
          {
            label: 'regression',
            data: data.map(
              (element, index) => regressionLine.predict(index)[1]
            ),
            pointRadius: 0,
            fill: false,
            borderDash: [20, 10],
          },
          {
            label: 'regression+1',
            data: data.map(
              (element, index) => regressionLine.predict(index)[1] + stdDev
            ),
            pointRadius: 0,
            fill: false,
            borderDash: [10, 15],
          },
          {
            label: 'regression+2',
            data: data.map(
              (element, index) => regressionLine.predict(index)[1] + stdDev * 2
            ),
            pointRadius: 0,
            fill: false,
            borderDash: [5, 20],
          },
        ],
      },
      options: {
        scales: {
          yAxes: [
            {
              display: true,
              ticks: {
                min: 0,
              },
            },
          ],
        },
        plugins: {
          colorSchemes: {
            scheme: 'tableau.Tableau10',
          },
        },
      },
    })
    this.setState({ chart: chart })
  }
  updateTicker(event) {
    this.setState({
      stockTicker: event.target.value,
    })
  }

  refreshGraph() {
    this.setState({ refreshing: true })
    getStockData(this.state.stockTicker)
      .then(response => {
        this.setState({ refreshing: false })
        const data = response.data
        if (!data['Meta Data']) {
          throw data
        }
        this.setState({
          stockData: data,
        })
        this.createGraph()
      })
      .catch(err => {
        this.setState({ refreshing: false })
        alert('Could not retrieve data for this stock')
        console.error(err)
      })
  }

  render() {
    return (
      <Layout>
        <Container>
          <Title isSize="1" hasTextAlign="centered">
            Stock historical linear regression
          </Title>
          <Box>
            <Columns>
              <Column
                isSize={{ widescreen: '2/3', mobile: 'full' }}
                isOffset={{ widescreen: 2 }}
              >
                <Field isHorizontal>
                  <FieldLabel isNormal>
                    <a
                      href="https://www.worldtradingdata.com/search?q="
                      target="_blank"
                    >
                      Stock ticker:
                    </a>
                  </FieldLabel>
                  <FieldBody>
                    <Field hasAddons>
                      <Control isExpanded>
                        <Input
                          type="text"
                          placeholder="SGO.PA"
                          onChange={this.updateTicker.bind(this)}
                          onKeyPress={this.handleKeyPress.bind(this)}
                        />
                      </Control>
                      <Control>
                        <Button
                          isColor="success"
                          onClick={this.refreshGraph.bind(this)}
                          isLoading={this.state.refreshing}
                        >
                          Go
                        </Button>
                      </Control>
                    </Field>
                  </FieldBody>
                </Field>
              </Column>
            </Columns>
          </Box>
          {this.state.renderCanvas ? (
            <Box>
              <canvas id="chart-canvas" className="container" />
              <Tag isColor="dark">
                Annual performance: {Math.round((this.state.annualPerformance * 100)*100)/100}%
              </Tag>
              <Tag isColor="info">
                Standard deviation: {Math.round(this.state.standardDeviation * 100) / 100}
              </Tag>
            </Box>
          ) : (
            ''
          )}
        </Container>
      </Layout>
    )
  }
}

export default IndexPage
